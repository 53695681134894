"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentHistory = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const __1 = require("..");
const quote_utils_1 = require("@truxweb/quote-utils");
const date_fns_1 = require("date-fns");
const SHIPMENT_BOOLEAN_PROPS = [
    'isFscActive',
    'isDelayed',
    'isAllInRate',
    'isTaxable',
    'isCrossBorder',
];
const ShipmentHistory = ({ commentUserData, history, language, t, }) => {
    let shouldShowHistoryNotice = false;
    const startFromIndex = 1;
    // Format the history elements into something mirroring a shipment comment and use that to display the history
    // If the first history element is not `REQUIRES_DOCUMENTS` status, then show that diff, otherwise skip the first element
    // Also show a note that this first history element is missing
    if (history[0].shipment.status !== schemas_1.EShipmentStatusV1.REQUIRES_DOCUMENTS) {
        shouldShowHistoryNotice = true;
    }
    const formattedHistoryElements = (0, react_1.useMemo)(() => {
        let previousElement;
        const comments = [];
        history.forEach((record, index) => {
            if (index < startFromIndex) {
                previousElement = record;
                return;
            }
            const diffedProps = Object.entries(record.shipment)
                .map(([key, value]) => {
                if (SHIPMENT_BOOLEAN_PROPS.includes(key)) {
                    if (Boolean(previousElement.shipment[key]) !== Boolean(value)) {
                        return key;
                    }
                }
                else if (previousElement.shipment[key] !== value &&
                    !['ltlCargoDimensions', 'dateUpdated'].includes(key)) {
                    return key;
                }
                return null;
            })
                .filter((value) => Boolean(value));
            if (!diffedProps.length)
                return;
            let comment = ``;
            diffedProps.forEach((key) => {
                const previousValue = previousElement.shipment[key];
                const currentValue = record.shipment[key];
                if (SHIPMENT_BOOLEAN_PROPS.includes(key)) {
                    comment += `${key}: ${Boolean(previousValue)} → ${Boolean(currentValue)} \n`;
                }
                else if (key.includes('Date')) {
                    comment += `${key}: ${previousValue
                        ? (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date(previousValue))
                        : null} → ${currentValue
                        ? (0, quote_utils_1.formatQuoteDetailsLocalizedDateString)(language, new Date(currentValue))
                        : null} \n`;
                }
                else {
                    comment += `${key}: ${previousValue} → ${currentValue} \n`;
                }
            });
            const currentDate = new Date(record.dateCreated);
            const previousDate = new Date(previousElement.dateCreated);
            const shouldShowDayBreak = index === startFromIndex ? true : !(0, date_fns_1.isSameDay)(currentDate, previousDate);
            comments.push({
                comment: {
                    comment,
                    dateCreated: record.dateCreated,
                    id: record.id,
                    isVisibleExternally: true,
                    shipmentId: record.shipment.id,
                    userExtId: record.extId,
                    userName: record.userName || record.extId,
                    userType: record.userType || schemas_1.EUserTypeV1.TRUXWEB,
                },
                shouldShowAvatar: false,
                shouldShowDayBreak,
                shouldShowTimeStamp: false,
            });
            previousElement = record;
        });
        return comments;
    }, [history, language]);
    const notice = shouldShowHistoryNotice ? (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
        react_1.default.createElement(ux_1.Box, { mb: 3, ml: 1, mr: 1, mt: 1.5, width: '100%' },
            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:shipmentCreationHistoryRecordMissing'))))) : null;
    if (!formattedHistoryElements.length) {
        return (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
            react_1.default.createElement(ux_1.Box, { mb: 3, ml: 1, mr: 1, mt: 1.5, width: '100%' },
                react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:noHistoryAvailable')))));
    }
    return (react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
        notice,
        formattedHistoryElements.map((comment, i) => {
            return (react_1.default.createElement(ux_1.Grid, { item: true, key: comment.comment.id },
                react_1.default.createElement(ux_1.Box, { ml: 1, mr: 1, mt: 1.5 },
                    react_1.default.createElement(__1.ShipmentCommentDateHeader, { comment: comment, index: i, language: language, t: t }),
                    react_1.default.createElement(__1.ShipmentComment, { canAdministerComments: false, comment: comment.comment, isOwnComment: false, shouldShowAvatar: true, shouldShowTimeStamp: true, t: t, user: commentUserData === null || commentUserData === void 0 ? void 0 : commentUserData[comment.comment.userExtId], viewingUserType: schemas_1.EUserTypeV1.TRUXWEB }))));
        })));
};
exports.ShipmentHistory = ShipmentHistory;
