"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarrierLaneManagementForm = void 0;
/* eslint-disable max-lines */
const __1 = require("..");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("../../utils");
const schemas_1 = require("@truxweb/schemas");
const mapUtils_1 = require("../../mapUtils");
const react_1 = __importStar(require("react"));
const CarrierLaneManagementForm_styles_1 = require("./CarrierLaneManagementForm.styles");
// eslint-disable-next-line complexity
const CarrierLaneManagementForm = ({ additionalActions, applicationTheme, areButtonsDisabled, carrier, carrierLaneEquipment, equipmentPrefix, googleMapsApiKey, handleCancel, handleCreateLane, handleDownloadRateSheet, handleError, handleHideShapes, handleRouteToRateSheet, handleShowHelp, handleSuccess, handleUpdateLane, isFormDisabled, isLoading, isSaving, lane, laneId, language, newLanePrompt, pendingUpdateMessage, rateSheetLocations, rateSheetRegions, rateSheets, setShouldReset, shouldAllowRateSheetAssignment, shouldAllowRateSheetRemoval, shouldReset, t, zones, }) => {
    var _a;
    const [hasEquipmentError, setEquipmentError] = (0, react_1.useState)(false);
    const [isRateSheetAssignmentShown, setRateSheetAssignmentShown] = (0, react_1.useState)(false);
    const [isLaneBidirectional, setLaneBidiretional] = (0, react_1.useState)(Boolean(lane === null || lane === void 0 ? void 0 : lane.bidirectionalService));
    const classes = (0, CarrierLaneManagementForm_styles_1.useStyles)();
    const handleCloseRateSheetAssignmentSidebar = (0, react_1.useCallback)(() => {
        setRateSheetAssignmentShown(false);
    }, [setRateSheetAssignmentShown]);
    const { clearErrors, control, formState: { errors }, handleSubmit, register, reset, setError, setValue, watch, } = (0, react_hook_form_1.useForm)({
        defaultValues: {
            active: (lane === null || lane === void 0 ? void 0 : lane.id) ? (lane === null || lane === void 0 ? void 0 : lane.active) || false : true,
            bidirectionalService: (lane === null || lane === void 0 ? void 0 : lane.bidirectionalService) || false,
            carrierReference: (lane === null || lane === void 0 ? void 0 : lane.carrierReference) || '',
            comments: (lane === null || lane === void 0 ? void 0 : lane.comments) || '',
            destination: (lane === null || lane === void 0 ? void 0 : lane.destination) || null,
            destinationShapes: [],
            equipment: carrierLaneEquipment,
            locationsChanged: 0,
            origin: (lane === null || lane === void 0 ? void 0 : lane.origin) || null,
            originShapes: [],
            rateSheets: (lane === null || lane === void 0 ? void 0 : lane.rateSheets) || [],
            recordId: laneId || null,
            schedule: (lane === null || lane === void 0 ? void 0 : lane.serviceSchedule) || [],
        },
    });
    const { fields } = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'equipment',
    });
    const rateSheetFields = (0, react_hook_form_1.useFieldArray)({
        control,
        name: 'rateSheets',
    });
    const [originRateSheets, destinationRateSheets, globalRateSheet] = (0, react_1.useMemo)(() => {
        if (!rateSheetFields.fields)
            return [null, null, null];
        const globalRateSheet = (rateSheets || []).find(({ isGlobal }) => {
            return Boolean(isGlobal);
        });
        if (!(lane === null || lane === void 0 ? void 0 : lane.bidirectionalService))
            return [
                rateSheetFields.fields.map((record, index) => {
                    return Object.assign(Object.assign({}, record), { index });
                }),
                null,
                globalRateSheet,
            ];
        const origin = [];
        const destination = [];
        (rateSheetFields.fields || []).forEach((record, index) => {
            if (!record.locationType || record.locationType === schemas_1.EShipmentLocationTypeV1.ORIGIN) {
                origin.push(Object.assign(Object.assign({}, record), { index }));
            }
            if (record.locationType === schemas_1.EShipmentLocationTypeV1.DESTINATION) {
                destination.push(Object.assign(Object.assign({}, record), { index }));
            }
        });
        return [origin, destination, globalRateSheet];
    }, [rateSheetFields, lane, rateSheets]);
    const [laneOrigin, setLaneOrigin] = (0, react_1.useState)(null);
    const [laneDestination, setLaneDestination] = (0, react_1.useState)(null);
    const [backUpLaneOrigin, setBackUpLaneOrigin] = (0, react_1.useState)(null);
    const [backUpLaneDestination, setBackupLaneDestination] = (0, react_1.useState)(null);
    const [shouldMapReset, setShouldMapReset] = (0, react_1.useState)(false);
    const [isEditingLocations, setEditingLocations] = (0, react_1.useState)(false);
    const [shouldDirty, setShouldDirty] = (0, react_1.useState)(false);
    const [hasFormDataBeenLoaded, setHasFormDataBeenLoaded] = (0, react_1.useState)(false);
    const [locationMode, setLocationMode] = (0, react_1.useState)({
        destination: 'geopolitical',
        origin: 'geopolitical',
    });
    const [addresses, setAddresses] = (0, react_1.useState)({
        destination: null,
        origin: null,
    });
    const [addressNames, setAddressNames] = (0, react_1.useState)({
        destination: null,
        origin: null,
    });
    const [locationCoords, setLocationCoords] = (0, react_1.useState)({
        destination: null,
        origin: null,
    });
    const [locationShapes, setLocationShapes] = (0, react_1.useState)({
        destination: [],
        origin: [],
    });
    const unitOfDistance = carrier === null || carrier === void 0 ? void 0 : carrier.unitOfDistance;
    const manageRateSheetAssignment = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldAllowRateSheetAssignment) {
            // Find the rate sheet to append
            const selectedRateSheet = (rateSheets || []).find(({ id }) => {
                return data.carrierRateSheetId === id;
            });
            if (selectedRateSheet) {
                const newLaneRateSheet = Object.assign(Object.assign({}, data), { id: selectedRateSheet.id, rateSheet: selectedRateSheet, rateSheetId: data.carrierRateSheetId });
                rateSheetFields.append(newLaneRateSheet);
            }
            setRateSheetAssignmentShown(false);
        }
    }), [shouldAllowRateSheetAssignment, setRateSheetAssignmentShown, rateSheetFields, rateSheets]);
    const manageRateSheetRemoval = (0, react_1.useCallback)((data) => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldAllowRateSheetRemoval) {
            rateSheetFields.remove(data.index);
        }
    }), [shouldAllowRateSheetRemoval, rateSheetFields]);
    const locationToAddress = (0, react_1.useCallback)((location) => {
        return {
            addressLineOne: '',
            city: location.city || '',
            country: location.country,
            email: '',
            name: location.name,
            phone: '',
            postalCode: location.postalCode || '',
            province: location.province || '',
        };
    }, []);
    // Update the form values when lane has been fetched
    const handleSubmitSuccess = (0, react_1.useCallback)(
    // eslint-disable-next-line complexity
    (data) => __awaiter(void 0, void 0, void 0, function* () {
        clearErrors();
        let hasErrors = false;
        // Post submit validations
        // Require origin and destination
        if (!addresses.origin || !locationCoords.origin) {
            setError('origin', { message: t('common:mustHaveOrigin'), type: 'manual' });
            hasErrors = true;
        }
        if (!addresses.destination || !locationCoords.destination) {
            setError('destination', { message: t('common:mustHaveDestination'), type: 'manual' });
            hasErrors = true;
        }
        // Require at least one active Equipment
        const equipmentActive = data.equipment.map(({ active }) => {
            return active;
        });
        if (!equipmentActive.includes(true)) {
            // NOTE: Equipment Error is managed outside of react hook form
            // due to complexities with resetting the error state
            setEquipmentError(true);
            hasErrors = true;
        }
        else {
            setEquipmentError(false);
        }
        data.equipment.forEach((equipment, index) => {
            if (equipment.active &&
                !equipment.minimumFlatRate.value &&
                !equipment.ratePerDistance.value) {
                setError(`equipment.${index}.ratePerDistance.value`, { type: 'manual' });
                setError(`equipment.${index}.minimumFlatRate.value`, { type: 'manual' });
                hasErrors = true;
            }
        });
        if (hasErrors) {
            return;
        }
        try {
            let destinationLocation = null;
            let originLocation = null;
            try {
                const originRequest = {
                    address: addresses.origin,
                    carrierId: carrier.id,
                    centerCoords: locationCoords.origin,
                    locationName: addressNames.origin || '',
                    shapes: locationShapes.origin,
                    shouldIgnoreZones: locationMode.origin === 'geopolitical',
                };
                originLocation = (0, utils_1.transformLocationFormDataToCarrierLocationV1)(originRequest);
                const destinationRequest = {
                    address: addresses.destination,
                    carrierId: carrier.id,
                    centerCoords: locationCoords.destination,
                    locationName: addressNames.destination || '',
                    shapes: locationShapes.destination,
                    shouldIgnoreZones: locationMode.destination === 'geopolitical',
                };
                destinationLocation = (0, utils_1.transformLocationFormDataToCarrierLocationV1)(destinationRequest);
            }
            catch (err) {
                handleError(new Error(t('common:unableToAddLocations')));
                return;
            }
            const updatedLane = yield (0, utils_1.convertFormDataToCarrierLaneV1)(Object.assign(Object.assign({}, data), { destination: destinationLocation, origin: originLocation }), carrier.id);
            if (lane === null || lane === void 0 ? void 0 : lane.id) {
                yield handleUpdateLane([updatedLane]);
            }
            else {
                yield handleCreateLane([updatedLane]);
            }
            if (handleSuccess)
                handleSuccess(t('common:updateSuccess'));
            return;
        }
        catch (err) {
            handleError(err);
            return;
        }
    }), [
        handleError,
        lane,
        handleSuccess,
        setEquipmentError,
        clearErrors,
        t,
        handleCreateLane,
        handleUpdateLane,
        locationMode,
        carrier,
        addresses,
        locationCoords,
        addressNames,
        locationShapes,
        setError,
    ]);
    watch((data) => {
        if (data.bidirectionalService && !isLaneBidirectional) {
            setLaneBidiretional(true);
        }
        else if (!data.bidirectionalService && isLaneBidirectional) {
            setLaneBidiretional(false);
        }
    });
    (0, react_1.useEffect)(() => {
        if (shouldDirty) {
            setValue('locationsChanged', 1, { shouldDirty: true });
            setShouldDirty(false);
        }
    }, [shouldDirty, setShouldDirty, setValue]);
    // eslint-disable-next-line complexity
    (0, react_1.useEffect)(() => {
        if (!hasFormDataBeenLoaded && carrierLaneEquipment && carrier) {
            setHasFormDataBeenLoaded(true);
            if (lane !== null) {
                const addresses = {
                    destination: locationToAddress(lane.destination),
                    origin: locationToAddress(lane.origin),
                };
                const addressNames = {
                    destination: lane === null || lane === void 0 ? void 0 : lane.destination.name,
                    origin: lane === null || lane === void 0 ? void 0 : lane.origin.name,
                };
                const locationCoords = {
                    destination: lane === null || lane === void 0 ? void 0 : lane.destination.zones[0].centerCoords,
                    origin: lane === null || lane === void 0 ? void 0 : lane.origin.zones[0].centerCoords,
                };
                setLocationCoords(locationCoords);
                setAddresses(addresses);
                setAddressNames(addressNames);
                const equipmentMap = {};
                (lane.equipment || []).forEach((record) => {
                    if (record.id) {
                        equipmentMap[record.id] = record;
                    }
                });
                setValue('origin', lane === null || lane === void 0 ? void 0 : lane.origin);
                setValue('destination', lane === null || lane === void 0 ? void 0 : lane.destination);
                setValue('comments', (lane === null || lane === void 0 ? void 0 : lane.comments) || '');
                setValue('recordId', (lane === null || lane === void 0 ? void 0 : lane.id) || null);
                setValue('bidirectionalService', lane === null || lane === void 0 ? void 0 : lane.bidirectionalService);
                setValue('schedule', lane === null || lane === void 0 ? void 0 : lane.serviceSchedule);
                setValue('carrierReference', (lane === null || lane === void 0 ? void 0 : lane.carrierReference) || '');
                setHasFormDataBeenLoaded(true);
                setLaneOrigin(lane === null || lane === void 0 ? void 0 : lane.origin);
                setLaneDestination(lane === null || lane === void 0 ? void 0 : lane.destination);
            }
            else {
                setEditingLocations(true);
            }
        }
    }, [
        setLocationCoords,
        setAddressNames,
        setAddresses,
        carrier,
        setLaneOrigin,
        locationToAddress,
        setLaneDestination,
        laneId,
        carrierLaneEquipment,
        lane,
        setValue,
        hasFormDataBeenLoaded,
        setHasFormDataBeenLoaded,
        setEditingLocations,
    ]);
    (0, react_1.useEffect)(() => {
        if (shouldReset && setShouldReset) {
            reset();
            setLaneOrigin(null);
            setLaneDestination(null);
            setBackUpLaneOrigin(null);
            setBackupLaneDestination(null);
            setShouldReset(false);
            setLocationMode({
                destination: 'geopolitical',
                origin: 'geopolitical',
            });
            setAddresses({
                destination: null,
                origin: null,
            });
            setAddressNames({
                destination: null,
                origin: null,
            });
            setLocationCoords({
                destination: null,
                origin: null,
            });
            locationShapes.destination.forEach((shape) => {
                shape.overlay.setMap(null);
            });
            locationShapes.origin.forEach((shape) => {
                shape.overlay.setMap(null);
            });
            setLocationShapes({
                destination: [],
                origin: [],
            });
            setShouldMapReset(true);
        }
    }, [
        setShouldMapReset,
        shouldReset,
        locationShapes,
        setShouldReset,
        reset,
        setLaneOrigin,
        setLaneDestination,
        setBackUpLaneOrigin,
        setBackupLaneDestination,
        setLocationMode,
        setAddresses,
        setAddressNames,
        setLocationCoords,
        setLocationShapes,
    ]);
    const laneSchedule = (react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.serviceSchedule) || [], name: "schedule", render: ({ field: { onChange, value }, fieldState: { error } }) => {
            return (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Box, { mb: 2 },
                    react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:schedule'))),
                        react_1.default.createElement(ux_1.Box, { ml: 2 },
                            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true }, Boolean(error) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:pleaseAddSchedule') || '' },
                                react_1.default.createElement(ux_1.ErrorOutlinedIcon, { style: { color: 'red' } }))))))),
                react_1.default.createElement(__1.ScheduleToggle, { isDisabled: isFormDisabled, onChange: (newSchedule) => {
                        onChange(newSchedule);
                    }, shouldUseSmallVariant: true, t: t, value: value })));
        }, rules: { required: true } }));
    const laneBidirectional = (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", item: true, justifyContent: "space-between", xs: true },
        react_1.default.createElement(__1.ExplainedString, { color: "primaryLight", explanation: t('common:laneDirectionDescription'), fontStyle: "semibold", isTooltip: true, variant: "bodyMedium" }, t('common:laneDirection')),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, name: "bidirectionalService", render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(__1.EnumRadioGroup, { direction: "row", getLabelText: (value) => {
                            if (value === schemas_1.EYesNoV1.YES)
                                return t('common:bidirectional');
                            if (value === schemas_1.EYesNoV1.NO)
                                return t('common:unidirectional');
                            return value;
                        }, isDisabled: isFormDisabled, onChange: (value) => {
                            onChange(value === schemas_1.EYesNoV1.YES);
                        }, sourceEnum: schemas_1.EYesNoV1, t: t, value: value ? schemas_1.EYesNoV1.YES : schemas_1.EYesNoV1.NO }));
                } }))));
    const referenceCard = (react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mb: 3 },
                react_1.default.createElement(ux_1.Grid, { alignItems: "flex-end", container: true, direction: "row", justifyContent: "space-between" },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: 4 },
                        react_1.default.createElement(__1.FormField, { InputLabelProps: {
                                shrink: true,
                            }, control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.carrierReference) || '', id: `carrierReference`, inputProps: {
                                maxLength: 15,
                                required: false,
                            }, isDisabled: isFormDisabled, isRequired: false, label: t('common:reference'), shouldOverrideLabelStyles: true, shouldUseModernVariant: true, t: t })),
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.id) ? (lane === null || lane === void 0 ? void 0 : lane.active) || false : true, name: "active", render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row" },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Box, { mr: 3 },
                                            react_1.default.createElement(__1.ActiveStatusDot, { isActive: value || false, t: t }))),
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(ux_1.Switch, { checked: value, color: "primary", disabled: isFormDisabled, onChange: onChange }))));
                            } }))))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Box, { mb: 3 },
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" }, [laneOrigin, laneDestination].map((location, index) => {
                    const locationName = [];
                    if (location) {
                        if (!(location === null || location === void 0 ? void 0 : location.shouldIgnoreZones)) {
                            locationName.push(location === null || location === void 0 ? void 0 : location.city);
                        }
                        locationName.push(location === null || location === void 0 ? void 0 : location.province);
                        if (!(location === null || location === void 0 ? void 0 : location.shouldIgnoreZones)) {
                            locationName.push(location === null || location === void 0 ? void 0 : location.postalCode);
                        }
                        locationName.push(location === null || location === void 0 ? void 0 : location.country);
                    }
                    const label = index === 0 ? t('common:origin') : t('common:destination');
                    return (react_1.default.createElement(ux_1.Grid, { item: true, key: index, xs: 6 },
                        react_1.default.createElement(ux_1.Box, { ml: index !== 0 ? 3 : 0 },
                            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, label),
                            react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:location'))),
                                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                                                isEditingLocations && react_1.default.createElement(ux_1.Typography, null, t('common:tbd')),
                                                !isEditingLocations && (react_1.default.createElement(ux_1.Typography, null, locationName.join(', '))))))),
                                react_1.default.createElement(ux_1.Grid, { item: true },
                                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "row" },
                                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                            react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:serviceArea'))),
                                        react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "flex-end" },
                                                isEditingLocations && react_1.default.createElement(ux_1.Typography, null, t('common:tbd')),
                                                (location === null || location === void 0 ? void 0 : location.shouldIgnoreZones) && (react_1.default.createElement(ux_1.Typography, null, t('common:entireArea'))),
                                                !isEditingLocations && (react_1.default.createElement(react_1.default.Fragment, null, (location === null || location === void 0 ? void 0 : location.shouldIgnoreZones) === false && (react_1.default.createElement(react_1.default.Fragment, null,
                                                    (location === null || location === void 0 ? void 0 : location.zones[0].shape) === schemas_1.EZoneShapeTypeV1.CIRCLE && (react_1.default.createElement(ux_1.Typography, null,
                                                        (0, mapUtils_1.getDistanceDisplay)(location, unitOfDistance),
                                                        t('common:unitOfDistance', {
                                                            unit: unitOfDistance,
                                                        }),
                                                        ' ',
                                                        t('common:radius'))),
                                                    (location === null || location === void 0 ? void 0 : location.zones[0].shape) === schemas_1.EZoneShapeTypeV1.POLYGON && (react_1.default.createElement(ux_1.Typography, null,
                                                        (0, mapUtils_1.getDistanceDisplay)(location, unitOfDistance),
                                                        ' ',
                                                        t('common:unitOfDistanceSquared', {
                                                            unit: unitOfDistance,
                                                        }),
                                                        ' ',
                                                        t('common:covered')))))))))))))));
                })))),
        react_1.default.createElement(ux_1.Grid, { item: true },
            react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyMedium" }, t('common:internalNotes')),
            react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: (lane === null || lane === void 0 ? void 0 : lane.comments) || '', name: "comments", render: ({ field: { onChange, value } }) => {
                    return (react_1.default.createElement(ux_1.TextField, { disabled: isFormDisabled, fullWidth: true, id: "comments", multiline: true, onChange: onChange, rows: 5, style: {
                            background: '#fff',
                        }, value: value, variant: "outlined" }));
                } }))));
    const equipmentCard = (react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
        react_1.default.createElement(ux_1.Box, { mb: 2 }, Boolean(hasEquipmentError) && (react_1.default.createElement(ux_1.Tooltip, { title: t('common:pleaseSelectEquipment') || '' },
            react_1.default.createElement(ux_1.ErrorOutlinedIcon, { style: { color: 'red' } })))),
        react_1.default.createElement(__1.CarrierLaneEquipmentManagementForm, { carrier: carrier, control: control, equipmentData: carrierLaneEquipment, equipmentPrefix: equipmentPrefix, fields: fields, isFormDisabled: isFormDisabled, isOnlyActiveShown: false, register: register, setValue: setValue, shouldUseColumns: false, t: t })));
    const mapCard = (react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
        react_1.default.createElement(react_1.default.Fragment, null, !isEditingLocations && react_1.default.createElement(ux_1.Box, { mb: 3 }, laneBidirectional)),
        react_1.default.createElement(ux_1.Grid, { item: true, style: {
                position: 'relative',
            } },
            !isEditingLocations && (react_1.default.createElement(ux_1.Box, { style: {
                    left: 16,
                    position: 'absolute',
                    top: 16,
                    zIndex: 2,
                } },
                react_1.default.createElement(ux_1.Button, { color: "primary", disabled: isFormDisabled, onClick: () => {
                        setEditingLocations(true);
                    }, startIcon: react_1.default.createElement(ux_1.EditIcon, null), variant: "contained" },
                    react_1.default.createElement(ux_1.Typography, { style: { color: '#fff' }, variant: "body1" }, t('common:editLocations'))))),
            react_1.default.createElement(__1.LaneMap, { addressNames: addressNames, addresses: addresses, applicationTheme: applicationTheme, destination: laneDestination, geocodeByAddress: mapUtils_1.geocodeByAddress, geocodeByPlaceId: mapUtils_1.geocodeByPlaceId, googleMapsApiKey: googleMapsApiKey, handleCancelEdits: () => {
                    const restoreOrigin = backUpLaneOrigin || laneOrigin;
                    const restoreDestination = backUpLaneDestination || laneDestination;
                    setLaneOrigin(restoreOrigin ? Object.assign({}, restoreOrigin) : null);
                    setLaneDestination(restoreDestination ? Object.assign({}, restoreDestination) : null);
                    setEditingLocations(false);
                }, handleError: handleError, handleHideShapes: handleHideShapes, handleSetAddress: setAddresses, handleSetAddressNames: setAddressNames, handleSetLocationCoords: setLocationCoords, handleSetLocationMode: setLocationMode, handleSetLocationShapes: setLocationShapes, handleSetLocationZone: (zone, locationType) => {
                    const handler = locationType === 'origin' ? setLaneOrigin : setLaneDestination;
                    let location = locationType === 'origin' ? laneOrigin : laneDestination;
                    const zones = zone !== null ? [zone] : [];
                    if (!location)
                        location = {
                            carrierId: carrier.id,
                            city: '',
                            country: '',
                            name: (zone === null || zone === void 0 ? void 0 : zone.name) || '',
                            postalCode: '',
                            province: '',
                            shouldIgnoreZones: false,
                            zones,
                        };
                    const backupHandler = locationType === 'origin' ? setBackUpLaneOrigin : setBackupLaneDestination;
                    backupHandler(Object.assign({}, location));
                    handler(Object.assign(Object.assign({}, location), { zones }));
                }, hasDestinationError: Boolean(errors.destination), hasOriginError: Boolean(errors.origin), height: isEditingLocations ? 828 : 464, isInEditMode: isEditingLocations, laneId: lane === null || lane === void 0 ? void 0 : lane.id, language: language, locationCoords: locationCoords, locationModes: locationMode, locationShapes: locationShapes, mapTheme: ux_1.LIGHT_MAP_THEME, origin: laneOrigin, setDirty: () => {
                    setShouldDirty(true);
                }, setShouldReset: setShouldMapReset, shouldReset: shouldMapReset, t: t, width: isEditingLocations ? undefined : 430, zones: zones })),
        react_1.default.createElement(react_1.default.Fragment, null,
            isEditingLocations && (react_1.default.createElement(ux_1.Box, { mt: 3 },
                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, t('common:serviceDetails')),
                react_1.default.createElement(ux_1.Grid, { container: true, direction: "row", spacing: 2 },
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true }, laneBidirectional),
                    react_1.default.createElement(ux_1.Grid, { item: true, xs: true }, laneSchedule)))),
            !isEditingLocations && (react_1.default.createElement(ux_1.Grid, { alignItems: "flex-start", container: true, direction: "row", justifyContent: "center" },
                react_1.default.createElement(ux_1.Box, { mt: 3 }, laneSchedule))))));
    const rateSheetCard = (react_1.default.createElement(ux_1.FlatCard, { className: classes.card },
        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", item: true, xs: true },
            react_1.default.createElement(ux_1.Grid, { alignItems: "center", container: true, direction: "row", item: true, justifyContent: "space-between", xs: true },
                react_1.default.createElement(__1.ExplainedString, { color: "primaryLight", explanation: t('common:rateSheetsExplanation'), fontStyle: "semibold", isTooltip: true, variant: "bodyMedium" }, t('common:rateSheets')),
                Boolean(shouldAllowRateSheetAssignment) && (react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.StandardButton, { isDisabled: isRateSheetAssignmentShown, onClick: () => setRateSheetAssignmentShown(true) }, t('common:assignRateSheet'))))),
            isLaneBidirectional && (react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { className: classes.help, mt: 3, p: 2 },
                    react_1.default.createElement(ux_1.Box, { ml: 1.25 },
                        react_1.default.createElement(ux_1.Typography, null, t('common:bidirectionalLaneRateSheetsHelpCTA'))),
                    handleShowHelp !== undefined && (react_1.default.createElement(ux_1.Box, { mt: 2 },
                        react_1.default.createElement(ux_1.StandardButton, { onClick: () => handleShowHelp('bidirectionalLaneRateSheets') }, t('common:clickForDetails'))))))),
            react_1.default.createElement(react_1.default.Fragment, null,
                (Boolean(rateSheetFields.fields.length) || Boolean(globalRateSheet)) && (react_1.default.createElement(ux_1.Box, { mt: 3, style: { width: '100%' } },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        (lane === null || lane === void 0 ? void 0 : lane.bidirectionalService) &&
                            ((originRateSheets === null || originRateSheets === void 0 ? void 0 : originRateSheets.length) !== 0 || Boolean(globalRateSheet)) && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.ExplainedString, { explanation: t('common:originRateSheetsExplained'), fontStyle: "semibold", isTooltip: true, variant: "bodyMedium" }, t('common:origin')))),
                        (originRateSheets === null || originRateSheets === void 0 ? void 0 : originRateSheets.length) !== 0 && (react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(__1.LaneRateSheetList, { handleDownloadRateSheet: handleDownloadRateSheet, handleRemoveRateSheet: shouldAllowRateSheetRemoval ? manageRateSheetRemoval : undefined, handleRouteToRateSheet: handleRouteToRateSheet, locationType: schemas_1.EShipmentLocationTypeV1.ORIGIN, rateSheetLocations: rateSheetLocations, rateSheetRegions: rateSheetRegions, rateSheets: originRateSheets, t: t }))),
                        (originRateSheets === null || originRateSheets === void 0 ? void 0 : originRateSheets.length) === 0 && Boolean(globalRateSheet) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
                            react_1.default.createElement(__1.LaneRateSheetList, { handleDownloadRateSheet: handleDownloadRateSheet, handleRouteToRateSheet: handleRouteToRateSheet, locationType: schemas_1.EShipmentLocationTypeV1.ORIGIN, rateSheetLocations: rateSheetLocations, rateSheetRegions: rateSheetRegions, rateSheets: [{ id: globalRateSheet.id, rateSheet: globalRateSheet }], t: t }))),
                        (lane === null || lane === void 0 ? void 0 : lane.bidirectionalService) &&
                            ((destinationRateSheets === null || destinationRateSheets === void 0 ? void 0 : destinationRateSheets.length) !== 0 || Boolean(globalRateSheet)) && (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(__1.ExplainedString, { explanation: t('common:destinationRateSheetsExplained'), fontStyle: "semibold", isTooltip: true, variant: "bodyMedium" }, t('common:destination'))),
                            (destinationRateSheets === null || destinationRateSheets === void 0 ? void 0 : destinationRateSheets.length) !== 0 && (react_1.default.createElement(ux_1.Grid, { item: true },
                                react_1.default.createElement(__1.LaneRateSheetList, { handleDownloadRateSheet: handleDownloadRateSheet, handleRemoveRateSheet: shouldAllowRateSheetRemoval ? manageRateSheetRemoval : undefined, locationType: schemas_1.EShipmentLocationTypeV1.DESTINATION, rateSheetLocations: rateSheetLocations, rateSheetRegions: rateSheetRegions, rateSheets: destinationRateSheets, t: t }))),
                            (destinationRateSheets === null || destinationRateSheets === void 0 ? void 0 : destinationRateSheets.length) === 0 && Boolean(globalRateSheet) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "center" },
                                react_1.default.createElement(__1.LaneRateSheetList, { handleDownloadRateSheet: handleDownloadRateSheet, handleRouteToRateSheet: handleRouteToRateSheet, locationType: schemas_1.EShipmentLocationTypeV1.DESTINATION, rateSheetLocations: rateSheetLocations, rateSheetRegions: rateSheetRegions, rateSheets: [{ id: globalRateSheet.id, rateSheet: globalRateSheet }], t: t })))))))),
                !Boolean((_a = rateSheetFields === null || rateSheetFields === void 0 ? void 0 : rateSheetFields.fields) === null || _a === void 0 ? void 0 : _a.length) && !Boolean(globalRateSheet) && (react_1.default.createElement(ux_1.Box, { mt: 3, style: { width: '100%' } },
                    react_1.default.createElement(ux_1.Typography, { fontStyle: "semibold" }, t('common:noRateSheetsAssigned'))))))));
    if (!hasFormDataBeenLoaded)
        return react_1.default.createElement(__1.Loading, { isLoading: true, t: t });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        laneId === null && react_1.default.createElement(ux_1.Box, { mb: 3 }, newLanePrompt),
        react_1.default.createElement("form", { onSubmit: handleSubmit(handleSubmitSuccess) },
            react_1.default.createElement(__1.CarrierManagementWrapper, { additionalActions: additionalActions, areButtonsDisabled: areButtonsDisabled, isLoading: isLoading, isSaving: isSaving, onCancel: handleCancel, pendingUpdateMessage: pendingUpdateMessage, t: t },
                react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("input", { name: "locationsChanged", ref: () => register('locationsChanged'), type: "hidden" }),
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: isEditingLocations ? 'column-reverse' : 'row', justifyContent: "space-between", style: { overflow: 'hidden' } },
                        react_1.default.createElement(ux_1.Grid, { container: true, direction: "column", item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { mr: !isEditingLocations ? 2 : undefined },
                                referenceCard,
                                react_1.default.createElement(ux_1.Box, { mb: 2, mt: 2 }, equipmentCard))),
                        react_1.default.createElement(ux_1.Grid, { item: true, style: {
                                maxWidth: isEditingLocations ? '100%' : 496,
                                minWidth: isEditingLocations ? '100%' : 496,
                            }, xs: true },
                            react_1.default.createElement(ux_1.Box, { mb: 2 }, mapCard)),
                        react_1.default.createElement(ux_1.Grid, { item: true, style: { width: '100%' } },
                            react_1.default.createElement(ux_1.Box, { mb: 2 }, rateSheetCard)))))),
        shouldAllowRateSheetAssignment && (react_1.default.createElement(ux_1.RightHandSidebar, { handleClose: handleCloseRateSheetAssignmentSidebar, isOpen: isRateSheetAssignmentShown },
            react_1.default.createElement(react_1.default.Fragment, null, isRateSheetAssignmentShown && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "center" },
                    react_1.default.createElement(ux_1.Box, { mb: 3 },
                        react_1.default.createElement(ux_1.Typography, { color: "primaryLight", fontStyle: "semibold", variant: "bodyLarge" }, t('common:assignRateSheet')))),
                react_1.default.createElement(ux_1.FlatCard, null,
                    react_1.default.createElement(__1.ManageLaneRateSheets, { handleAssignRateSheet: manageRateSheetAssignment, handleCloseRateSheetAssignmentSidebar: handleCloseRateSheetAssignmentSidebar, isSaving: isSaving, lane: lane, laneId: laneId, rateSheetLocations: rateSheetLocations, rateSheetRegions: rateSheetRegions, rateSheets: rateSheets || [], t: t, usedRateSheets: {
                            [schemas_1.EShipmentLocationTypeV1.ORIGIN]: originRateSheets || [],
                            [schemas_1.EShipmentLocationTypeV1.DESTINATION]: destinationRateSheets || [],
                        } })))))))));
};
exports.CarrierLaneManagementForm = CarrierLaneManagementForm;
